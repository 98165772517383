/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable new-cap */
import '../styles/globals.css';
import { ThemeProvider } from '@mui/material';
import { SessionProvider } from 'next-auth/react';
import type { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { memo, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'use-http';

import { AuthProvider } from '../components/Providers/Auth';
import styles from '../styles/PageDefault.module.css';
import theme from '../theme';
import { getBackendUrl } from '../util/auth';
import { parseCookies } from '../util/cookies';
import { pageview } from '../util/pixel.js';
import { GA_TRACKING_ID } from './_document';

const Politicas = dynamic(() => import('../components/ModalPoliticas'));
const Footer = dynamic(() => import('../components/Footer'));
const Header = dynamic(() => import('../components/Header'));
const FacebookPixel = dynamic(() => import('../util/pixel.js'));
const GoogleTagManager = dynamic(() => import('../util/googletag.js'));

// eslint-disable-next-line require-jsdoc
function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps & { cookies: any }) {
  const router = useRouter();

  useEffect(() => {
    ReactGA.initialize(
      [{ trackingId: GA_TRACKING_ID }, { trackingId: 'AW-434646777' }],
      { testMode: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' },
    );
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute('lang', 'pt-BR');
    const handleRouteChange = (url: URL) => {
      if (ReactGA.gtag) {
        ReactGA.gtag('config', GA_TRACKING_ID, {
          page_path: url,
          page_title: url,
        });
      }
      pageview();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        id="gtagManager"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="goptimize"
        strategy="lazyOnload"
        src="https://www.googleoptimize.com/optimize.js?id=OPT-NW9P3ZD"
      />
      <Script id="monitoring">
        {`(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "fr29kvbd3i");
          `}
      </Script>
      <Script
        id="hs-script-loader"
        src="https://js.hs-scripts.com/24107400.js"
      />
      <noscript>
        <img
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=681750666552858&ev=PageView
            &noscript=1"
          alt="facebook"
        />
      </noscript>
      <Script id="crisp">
        {`window.$crisp=[];window.CRISP_WEBSITE_ID="ecb8217d-ad8a-4f52-ac61-80b398859c97";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
      </Script>
      <FacebookPixel />
      <GoogleTagManager />
      <SessionProvider session={session}>
        <Provider url={getBackendUrl()}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <div className={styles.container}>
                <Head>
                  <title>Devyx . Mude sua evolução_</title>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, viewport-fit=cover"
                  />
                </Head>
                <Header />
                <main className={styles.main}>
                  <Politicas />
                  <Component {...pageProps} />
                </main>
                <Footer />
              </div>
            </AuthProvider>
          </ThemeProvider>
        </Provider>
      </SessionProvider>
    </>
  );
}
MyApp.getInitialProps = async (appContext: AppContext) => {
  return {
    cookie: parseCookies(appContext.ctx.req),
  };
};

export default memo(MyApp);
