/* eslint-disable @typescript-eslint/naming-convention */
import {
  BreakpointsOptions,
  createTheme,
  PaletteOptions,
  SimplePaletteColorOptions,
  TypeBackground,
} from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    darker: Palette['primary'];
    white: Palette['primary'];
    black: Palette['primary'];
  }
  interface PaletteOptions {
    darker: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
  }

  interface TypeBackground {
    medium: string;
    dark: string;
  }
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const background: TypeBackground = {
  default: '#FAFBFC',
  paper: '#F5F6F9',
  medium: '#CCDCE5',
  dark: '#889AA5',
};

const palette: PaletteOptions = {
  primary: {
    main: '#0071BC',
  },
  secondary: {
    main: '#8CC63F',
  },
  darker: {
    main: '#3B3A34',
  },
  error: {
    main: '#FF5656',
  },
  warning: {
    main: '#FF5A00',
  },
  info: {
    main: '#278EFF',
  },
  success: {
    main: '#2BE062',
  },
  white: {
    main: '#ffffff',
  },
  black: {
    main: '#000000',
  },
  background,
};

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 320, // removes the `xs` breakpoint
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 2560,
  },
};

const theme = createTheme({
  breakpoints,
  palette,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: (palette.white as SimplePaletteColorOptions).main,
          borderRadius: '0px',
        },
        elevation24: {
          borderRadius: '20px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'var(--background-medium)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '72px',
          backgroundColor: (palette.white as SimplePaletteColorOptions).main,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        },
        colorPrimary: {
          backgroundColor: (palette.white as SimplePaletteColorOptions).main,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontFamily: 'Open Sans',
          fontWeight: 400,
          lineHeight: '120%',
          color: (palette.darker as SimplePaletteColorOptions).main,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: background.medium,
          transform: 'scale(1.3)',
          '&$checked': {
            color: (palette.primary as SimplePaletteColorOptions).main,
          },
          '&:hover': {
            color: (palette.primary as SimplePaletteColorOptions).main,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: background.dark,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: (palette.primary as SimplePaletteColorOptions).main,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: background.medium,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: (palette.primary as SimplePaletteColorOptions).main,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: (palette.primary as SimplePaletteColorOptions).main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: background.medium,
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '150%',
        },
        input: {
          color: (palette.darker as SimplePaletteColorOptions).main,
          background: (palette.white as SimplePaletteColorOptions).main,
          borderRadius: '4px',
        },
      },
    },
  },
});

export default theme;
