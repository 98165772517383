/* eslint-disable react/jsx-filename-extension */
import Script from 'next/script';
import { memo } from 'react';

const FB_PIXEL_ID = '681750666552858';

export const pageview = async () => {
  window.fbq('track', 'PageView');
};

export const initiateCart = async () => {
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
    window.fbq('track', 'InitiateCheckout', {
      content_ids: ['buildr'],
      eventref: 'initiate checkout',
    });
};

export const addToCart = async value => {
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
    window.fbq('track', 'AddToCart', { value: value, currency: 'BRL' });
};

export const purchase = async value => {
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
    window.fbq('track', 'Purchase', { value: value, currency: 'BRL' });
};

export const lead = async lead => {
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
    window.fbq('track', 'Lead', lead);
};

const FacebookPixel = () => {
  return (
    <Script id="facebook-pixel" strategy="lazyOnload">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${FB_PIXEL_ID});
        fbq('track', 'PageView');
      `}
    </Script>
  );
};

export default memo(FacebookPixel);
