/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import cookie from 'cookie';
import Cookies from 'js-cookie';

export function parseCookies(req?: any) {
  if (!req || !req.headers) {
    return {};
  }

  return cookie.parse(req.headers.cookie || '');
}

export function setCookie(
  key: string,
  value: string | object,
  options?: Cookies.CookieAttributes,
) {
  if (typeof value === 'object') value = JSON.stringify(value);
  Cookies.set(key, value, {
    ...options,
    secure: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? true : false,
  });
}

export function getCookie(
  key: string,
): string | Record<string, unknown> | undefined {
  let data = Cookies.get(key);
  try {
    if (data) {
      data = JSON.parse(data);
      return data;
    }
  } catch (e) {}

  return data;
}

export function deleteCookie(key: string) {
  return Cookies.remove(key);
}
